<script>
import VTextarea from 'vuetify/lib/components/VTextarea';
import VTextField from 'vuetify/lib/components/VTextField';
import TextField from '../TextField';
import config from './defaults';
import { componentProps } from '@/utils';

const defaultProps = {
  ...VTextarea.options.props,
  ...TextField.props
};

export default {
  name: 'TextareaField',
  mixins: [ TextField ],
  props: componentProps( defaultProps, config ),
  computed: {
    ...VTextarea.options.computed,
    rootClasses() {
      return {
        'textarea-field': true,
        'textarea-field--percentual': this.isPercentualProp('minHeight') || this.isPercentualProp('maxHeight'),
        ...TextField.computed.rootClasses.call(this),
        'sizeable-field': false
      };
    },
    classes() {
      return {
        ...VTextarea.options.computed.classes.call(this),
        ...VTextField.options.computed.classes.call(this)
      };
    }
  },
  watch: {
    ...VTextarea.options.watch,
    minHeight: 'applyMinMax',
    maxHeight: 'applyMinMax'
  },
  mounted() {
    setTimeout(() => {
      this.autoGrow && this.calculateInputHeight();
      this.applyMinMax();
    }, 0 );
  },
  methods: {
    ...VTextarea.options.methods,
    ...TextField.methods,
    isPercentualProp( prop ) {
      let value = this.measurableStyles[prop];
      return value && String(value).slice(-1) === '%';
    },
    applyMinMax() {

      const { input } = this.$refs;
      if ( ! input ) return;

      input.style['min-height'] = this.isPercentualProp('minHeight')
        ? ''
        : this.measurableStyles.minHeight;

      input.style['max-height'] = this.isPercentualProp('maxHeight')
        ? ''
        : this.measurableStyles.maxHeight;
    },
    onKeyDown(e) {
      VTextarea.options.methods.onKeyDown.call( this, e );
      if ( this.prevent && this.prevent(e)) {
        e.preventDefault();
        return false;
      }
    }
  },
  render(h) {
    return TextField.render.call( this, h, {
      height: null,
      minHeight: null
    });
  }
}
</script>

<style lang="scss">
.textarea-field {
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    padding: 0;
  }
  .v-textarea.v-text-field--enclosed {
    .v-text-field__slot {
      margin: 0;
      textarea {
        margin: 0;
        padding: 6px 12px;
      }
    }
  }
  .v-text-field.v-text-field--single-line > .v-input__control > .v-input__slot {
    min-height: auto;
  }
  &.textarea-field--percentual {
    position: relative;
    .v-textarea {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      bottom: -8px;
      .v-input__control {
        min-height: 100%;
        .v-input__slot {
          flex: 1 1 auto;
        }
        .v-text-field__details {
          flex: 0 0 auto;
        }
      }
    }
  }
}
</style>
